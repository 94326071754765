import React from "react";
import styled, {useTheme} from "styled-components";
import Icon from "./Icon";
import {useSpring, animated} from 'react-spring'

const Svg = styled(Icon)`
  height: auto;
  width: 100%;
  max-width: 18rem;
  align-self: center;
  //transform: translateX(-50%);
`;

export const EyeAnim = () => {
    const theme = useTheme();

    const anim = useSpring({
        loop: true,
        // to: [
        //     {x: 192.2 + 100},
        //     {x: 192.2},
        // ],
        // from: {x: 192.2},
        to: [
            {rotateZ: 360},
        ],
        from: {rotateZ: 0},
        config: {duration: 10000}
        // to: [
        //     {opacity: 1, color: '#ffaaee'},
        //     {opacity: 0, color: 'rgb(14,26,19)'},
        // ],
        // from: {opacity: 0, color: 'red'},
    })

    return (
        <>
            <Svg
                // viewBox="0 0 384.5 324.5"
                viewBox="0 0 459.9 324.5"
                // className="svg_wheel"
            >
                <mask id="eyeMask">
                    <path fill={theme.colors.white}
                          d="M422.4,271.5c0,0-86.1,53-192.2,53s-192.2-53-192.2-53S123.9,192,230.1,192S422.4,271.5,422.4,271.5z"/>
                </mask>
                {/*Eye*/}
                <path fill={theme.colors.paleGrey}
                      d="M422.4,271.5c0,0-86.1,53-192.2,53s-192.2-53-192.2-53S123.9,192,230.1,192S422.4,271.5,422.4,271.5z"/>
                <path fill={theme.colors.orange}
                      d="M230.1,192L230.1,192l166.7-96.3c-8.4-14.7-18.7-28.1-30.6-39.9L230.1,192L230.1,192z"/>
                <path fill={theme.colors.blue}
                      d="M230.1,192L230.1,192L93.9,55.8C82.1,67.7,71.8,81.1,63.4,95.7L230.1,192L230.1,192z"/>
                <path fill={theme.colors.paleBlue} d="M133.9,25.3c-14.7,8.4-28.1,18.6-40,30.5L230.1,192L133.9,25.3z"/>
                <path fill={theme.colors.black} d="M63.4,95.7c-16.1,28.1-25.3,60.9-25.3,96.3h192l0,0L63.4,95.7z"/>
                <path fill={theme.colors.paleGrey}
                      d="M230.1,192L230.1,192l96.2-166.7C298,9.2,265.2,0,230.1,0s-67.9,9.2-96.2,25.3L230.1,192L230.1,192z"/>
                <path fill={theme.colors.black} d="M230.1,192h192c0-35.4-9.2-68.2-25.3-96.3L230.1,192L230.1,192z"/>
                <path fill={theme.colors.yellow} d="M366.3,55.8c-11.9-11.8-25.3-22.1-40-30.5L230.1,192L366.3,55.8z"/>
                <g
                    mask="url(#eyeMask)"
                >
                    <animated.g
                        style={{transformOrigin: "240px 440px", ...anim}}
                    >
                        <animated.ellipse
                            fill={theme.colors.orange}
                            cx="230.1"
                            cy="231.5"
                            rx="64"
                            ry="63.4"
                        />
                        <animated.ellipse
                            fill={theme.colors.black}
                            cx="230.1"
                            cy="231.5"
                            rx="32.8"
                            ry="32.5"
                        />
                        <animated.ellipse
                            fill={theme.colors.yellow}
                            cx="63.4"
                            cy="519.8"
                            rx="63.4"
                            ry="64"
                        />
                        <animated.ellipse
                            fill={theme.colors.black}
                            cx="63.4"
                            cy="519.8"
                            rx="32.5"
                            ry="32.8"
                        />
                        <animated.ellipse
                            fill={theme.colors.blue}
                            cx="396.4"
                            cy="520"
                            rx="63.4"
                            ry="64"
                        />
                        <animated.ellipse
                            fill={theme.colors.black}
                            cx="396.4"
                            cy="520"
                            rx="32.5"
                            ry="32.8"
                        />
                    </animated.g>
                </g>

                {/*<mask id="eyeMask">*/}
                {/*    <path fill={theme.colors.white}*/}
                {/*          d="M384.5,271.5c0,0-86.1,53-192.2,53S0,271.5,0,271.5S86.1,192,192.2,192S384.5,271.5,384.5,271.5z"/>*/}
                {/*    /!* oeil *!/*/}
                {/*</mask>*/}

                {/*<path fill={theme.colors.paleGrey}*/}
                {/*      d="M384.5,271.5c0,0-86.1,53-192.2,53S0,271.5,0,271.5S86.1,192,192.2,192S384.5,271.5,384.5,271.5z"/>*/}

                {/*<g*/}
                {/*    mask="url(#eyeMask)"*/}
                {/*>*/}
                {/*        /!* oeil *!/*/}
                {/*        /!* iris cx="192.2" cy="231.5" r="79.1"*!/*/}
                {/*        <animated.circle*/}
                {/*            fill={theme.colors.orange}*/}
                {/*            // cx={anim.x}*/}
                {/*            cx="192.2"*/}
                {/*            cy="231.5"*/}
                {/*            r="79.1"*/}
                {/*            style={{transform: "translateX(192.2px) translateY(550px)",...anim}}*/}
                {/*        />*/}
                {/*        /!* pupille cx="192.2" cy="231.5" r="40.9"*!/*/}
                {/*        <animated.circle*/}
                {/*            fill={theme.colors.black}*/}
                {/*            // cx={anim.x}*/}
                {/*            cx="192.2"*/}
                {/*            cy="231.5"*/}
                {/*            r="40.9"*/}
                {/*            style={{transform: "translateX(192.2px) translateY(550px)",...anim}}*/}
                {/*            // style={{transform: `translateY(10px) rotateY(${anim.x}deg)`}}*/}
                {/*        />*/}
                {/*</g>*/}

                {/*<path fill={theme.colors.orange}*/}
                {/*      d="M192.2,192L192.2,192L359,95.7c-8.4-14.7-18.7-28.1-30.6-39.9L192.2,192L192.2,192z"/>*/}

                {/*<path fill={theme.colors.blue}*/}
                {/*      d="M192.2,192L192.2,192L56.1,55.8C44.2,67.7,33.9,81.1,25.5,95.7L192.2,192L192.2,192z"/>*/}
                {/*<path fill={theme.colors.paleBlue} d="M96,25.3c-14.7,8.4-28.1,18.6-40,30.5L192.2,192L96,25.3z"/>*/}
                {/*<path fill={theme.colors.black} d="M25.5,95.7C9.4,123.8,0.2,156.6,0.2,192h192l0,0L25.5,95.7z"/>*/}
                {/*<path fill={theme.colors.paleGrey}*/}
                {/*      d="M192.2,192L192.2,192l96.2-166.7C260.2,9.2,227.3,0,192.2,0S124.3,9.2,96,25.3L192.2,192L192.2,192z"/>*/}
                {/*<path fill={theme.colors.black} d="M192.2,192h192c0-35.4-9.2-68.2-25.3-96.3L192.2,192L192.2,192z"/>*/}
                {/*<path fill={theme.colors.yellow} d="M328.4,55.8c-11.9-11.8-25.3-22.1-40-30.5L192.2,192L328.4,55.8z"/>*/}

            </Svg>
        </>
    )
};
