import React from 'react';
import styled from "styled-components";
import Text from "./Text";
import slugify from "slugify";

const Comp = styled.div`
  border: 2px solid ${({theme}) => theme.colors.text};
  background-color: ${({theme}) => theme.colors.background};
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  cursor: pointer;

  :hover {
    background-color: ${({theme}) => theme.colors.text};
    color: ${({theme}) => theme.colors.background};
  }
`

const MenuButton = (props) => {
    return (
        // <a href={"#" + slugify(props.text, {replacement: "-", lower: true})}>
            <a href={"#" + props.index + "_" + slugify(props.text, {replacement: "-", lower: true})}>
            <Comp>
                <Text s="standard">
                    {props.text}
                </Text>
            </Comp>
        </a>
    );
};

export default MenuButton;