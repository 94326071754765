import * as React from "react"
import Layout from "../components/layout/Layout";
import Text from "../components/layout/Text";
import Spacer from "../components/layout/Spacer";
import Separator from "../components/layout/Separator";
import localize from "../components/localization/localize";
import {graphql} from "gatsby";
import Block from "../components/editorial/Block";
import ContentMenu from "../components/layout/ContentMenu";
import {useTimeContext} from "../contexts/TimeContext";
import {EyeAnim} from "../components/icons/EyeAnim";

// markup
const IndexPage = ({data, ...props}) => {
    const title = data.sanityPage.title
    const locale = props.pageContext.locale
    const intro = data.sanityPage.intro.content
    const content = data.sanityPage.content
    const sliderText = data.sanityPage.sliderText
    const chapters = content.filter(i => i.style === "h1")

    const time = useTimeContext();
    console.log(sliderText)

    return (
        <Layout title={title} sliderText={sliderText} locale={locale}>
            <Text s={locale === "ko" ? "titleKo" : "title"}>
                {time.quarter ? intro.find(i => i.time === time.quarter.time).bigTitle : "loading"}
            </Text>
            <Spacer s="medium"/>

            <Text s={locale === "ko" ? "subtitleKo" : "subtitle"}>
                {time.quarter ? intro.find(i => i.time === time.quarter.time).subTitle : ""}
            </Text>

            <Spacer s="large"/>

            <EyeAnim/>
            <Spacer s="small"/>
            <Spacer s="smaller"/>

            {/*<Separator/>*/}
            <Spacer s="medium"/>

            <Text s={locale === "ko" ? "standardKo" : "standard"}>
                {time.quarter ? intro.find(i => i.time === time.quarter.time).smallText : ""}
            </Text>

            <Spacer s="large"/>
            <Separator full/>

            <ContentMenu chapters={chapters}/>
            <Spacer s="medium"/>

            <Block data={content}/>
        </Layout>
    )
}

export default localize(IndexPage)

export const query = graphql`
    {  sanityPage(_id: {eq: "1dc3b2fc-d131-4301-8fe8-9ca00b93f0ee"}) {
        title {
            _type
            en
            ko
            fr
        }
        sliderText {
            _rawEn
            _rawKo
            _rawFr
            _type
        }
        intro {
            content {
                bigTitle {
                    _type
                    en
                    ko
                    fr
                }
                smallText {
                    _type
                    en
                    ko
                    fr
                }
                subTitle {
                    _type
                    en
                    ko
                    fr
                }
                time
            }
        }
        content {
            _rawEn(resolveReferences: {maxDepth: 20})
            _rawKo(resolveReferences: {maxDepth: 20})
            _rawFr(resolveReferences: {maxDepth: 20})
            _type
        }
    }
    }
`;
