import React from 'react';
import styled from "styled-components";
import MenuButton from "./MenuButton";

const Comp = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  position: sticky;
  top: 0;
  z-index: 5;
  width: calc(100% + ${({theme}) => theme.paddings.left} + ${({theme}) => theme.paddings.right});
  padding-top: 1rem;
  //background-color: palegoldenrod;
  transform: translateX(-${({theme}) => theme.paddings.left});
  padding-left: 1rem;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: content-box;
  //background-color: wheat;
  //overflow-x: scroll;
  //width: 200%;
  // display:inline-block;
  padding-bottom: 1rem;
`

const ContentMenu = (props) => {
    const buttons = props.chapters.map((item, index) => {
        return (
            <MenuButton key={index} text={item.children[0].text} index={index + 1}/>
        )
    })

    return (
        <Comp>
            <Content>
                {buttons}
            </Content>
        </Comp>
    );
};

export default ContentMenu;