export const createLocaleTextGetter = languageCode => {
    const languages = [languageCode, "en"]; // last language in array is default;

    const localize = value => {
        if (value) {
            if (Array.isArray(value)) {
                return value.map(v => localize(v, languages));
            } else if (typeof value == "object") {
                if (/^locale[A-Z]/.test(value._type)) {
                    const language = languages.find(lang => value[lang]);
                    // if (value._type === "localeBlock") {
                    // 	console.log(value);
                    // 	console.log(languages);
                    // }

                    if (language) {
                        if (value[language]) {
                            return value[language];
                        } else {
                            return value["en"];
                        }
                    } else {
                        if (languages[0] === "fr" && value["_rawFr"]) {
                            return value["_rawFr"];
                        } else {
                            return value["_rawEn"];
                        }
                    }
                }
                return Object.keys(value).reduce((result, key) => {
                    result[key] = localize(value[key], languages);
                    return result;
                }, {});
            }
            return value;
        } else {
            return value;
        }
    };

    return localize;
};

export function trimText(text, charCount) {
    const truncated = text.slice(0, charCount);
    const trail = text.slice(charCount, charCount + 1) ? " [...]" : "";

    return truncated + trail;
}

export function timesOfDay() {
    const now = new Date(
        // 'December 17, 1995 3:00:00'
    );
    const hours = now.getHours()
    let time = -1

    if (hours >= 6 && hours < 10) {
        time = 0
    } else if (hours >= 10 && hours < 18) {
        time = 1
    } else if (hours >= 18 && hours < 22) {
        time = 2
    } else {
        time = 3
    }

    return time;
}
